/**
 * @namespace
 */
var site = site || {};
var generic = generic || {};
site.signin = site.signin || {};
site.account = site.account || {};

    /**
    * One-time call to collect specific RB Keys used for forget password.
    * @methodOf site.signin
    */
    
    site.signin.getRBKeys = function() {
        site.signin.rb = generic.rb("error_messages");
        site.signin.forgotPasswordEmailNotFound = site.signin.rb.get('incorrect_pwremind');
        site.signin.forgotPasswordNoEmailProvided = site.signin.rb.get('session_pw_hint') || "Please enter your email address.";
        site.signin.forgotPasswordMigratedUser = site.signin.rb.get('migrated.mobile_account.signin');
        site.signin.passwordResetSend = site.signin.rb.get('signin_error');
    }

/**
    * This method is used to set up the forget password functionality
    * on the site.
    * Takes the passed element in the DOM and gets the required form
    * nodes and places them within forgotPassArgs object.
    * site.signin.setForgetPassword() is then called if the param resetPassword
    * is set to true.
    * @param {Object} args
    * @param {Object} args.emailNode **REQUIRED** DOM element of either a
    * form element or wrapper element of the email.
    * @param {Object} args.errorListNode **REQUIRED** DOM element used to show
    * password hint or error messaging if hint is not available.
    * @param {Object} args.forgotPasswordLink **REQUIRED** DOM element of the
    * forget password link.
    * @params {element} forget link node set on dom:load
    * @methodOf site.signin
*/
site.signin.forgotPassword = function(args) {

    if ((args.emailNode.length > 1) || (!args.forgotPasswordLink)
        || (!args.errorListNode) ) {
        return null;
    }

    site.signin.getRBKeys();

    var errorListNode = args.errorListNode;
    var emailNode = args.emailNode;
    var forgotPasswordLink = args.forgotPasswordLink;
    var forgotPasswordNote = args.forgotPasswordNote;
    var forgotPasswordCopy = $("#lpw-text");
    var $passwordResetEmailNode = $('.js-password_reset_send__EMAIL_ADDRESS');
    // content may have been set on server side. If so, do not hide.
    if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length<1) {
        forgotPasswordCopy.hide();
    }

    if ($passwordResetEmailNode.length === 1) {
        emailNode.once().on('change', function() {
            var email = emailNode.val();
            $passwordResetEmailNode.val(email);
        });
    }

/*    
    * new business requirements want us to forego the password hint
    * we can 'skip' the hint and proceed normally through the JS calls
    * to request a new password which requires the submission of a hidden form
    * If this is not done properly please let me know
    * Jim Butler 11/2013
*/
    
    var skipPasswordHint = $('#skipHint').val() || 1;
    //console.log('hint is ' + skipPasswordHint);
    
    forgotPasswordLink.on('click', function(evt, context) {
        evt.preventDefault();
        forgotPasswordCopy.show();
        var email = site.signin.getEmailAddress(emailNode);
        
        if (email.length < 1) {
            $('.error_messages').empty();
            $('.error_messages').append("<li class='s' id='signin_error.email_address.'>" + site.signin.forgotPasswordNoEmailProvided + "</li>");
            
            // remove errors from new user section div
            $('#new-account > .error_messages').empty();
/*      
            generic.showErrors([{
                text: site.signin.forgotPasswordNoEmailProvided
            }], errorListNode);
*/
            generic.showErrorState(emailNode[0]);
            return null;
        }
        
        if ($passwordResetEmailNode.length === 1) {
            var $errors = $('.error_messages', context);
            $errors.empty();
            $passwordResetEmailNode.val(emailNode.val());
            var $passwordResetForm = $('.sign-in-page__password_reset_send form', context);
            var Hash = generic.Hash();
            var param = Hash.queryToJson($passwordResetForm.serialize());
            _.forEach(param, function (value, key) {
                param[key] = decodeURIComponent(value);
            });
            generic.jsonrpc.fetch({
              method: 'rpc.form',
              params: [param],
              onBoth: function(r) {
                var messages = r.getMessages();
                if (messages) {
                  $.each(messages, function(i, message) {
                    if (message.key === "request_sent") {
                      var $passwordResetSendMsg = $('#passwordResetSendMsg', context);
                      var hasPasswordResetSendMsg = $passwordResetSendMsg.length;
                      hasPasswordResetSendMsg ? $passwordResetSendMsg.show() :
                      $errors.append('<li id=\'passwordResetSendMsg\'>' + site.signin.passwordResetSend + '</li>');
                    }
                  });
                }
              }
            });
        } else {
            // skip the hint part of the password request
            // and just request a new password
            if(skipPasswordHint == 1 && args.resetPassword)
            {
                site.signin.requestPassword(email);

                // remove errors from new user section div
                $('#new-account > .error_messages').empty();
                return false;
            }
            
            // proceed normally with password retrieval
            else
            {
                site.signin.getPasswordHint({
                    email: email,
                    onSuccess: function(result) {
                        var responseData = result.getValue();
                        if (responseData) {
                            generic.template.get({
                                path: '/templates/password-hint.tmpl',
                                object: {
                                    hint: responseData
                                },
                                callback: function(html) {
                                    errorListNode.html(html);
                                    forgotPasswordNote.addClass("hidden");
                                    if (args.resetPassword) {
                                        site.signin.initResetPassword(emailNode);
                                    }
                                }
                            });
                        } else {
                            // user not found
                            errorListNode[0].innerHTML = '';
                            generic.showErrors([{
                                text: site.signin.forgotPasswordEmailNotFound
                            }], errorListNode);
                            generic.showErrorState(emailNode[0]);
                        };
                    },
                    onFailure: function(jsonRpcResponse) {
                        var errorObjectsArray = jsonRpcResponse.getMessages();
                        var errorKey = errorObjectsArray[0].key;
                        if (errorKey === 'user.missing_password_hint') {
                            site.signin.requestPassword(email);
                        } else if(errorKey == 'user.password_reset'){
                            generic.template.get({
                                path: '/templates/password-already-reset.tmpl',
                                callback: function(html) {
                                    errorListNode.html(html);
                                    forgotPasswordNote.addClass("hidden");
                                    if (args.resetPassword) {
                                        site.signin.initResetPassword(emailNode);
                                    }
                                }
                            });
                        } else {
                            generic.showErrors(errorObjectsArray, this.errListNode);
                            generic.showErrorState(site.account.signinEmailInput[0]);
                        }
                    }
                });
            }
        }
    });
};


/**
    * This method is used to retrieve a user's password hint.
    * @param {email} the user email that will be passed. **REQUIRED**
    * @param {onSucess}
    * @param {onFailure}
    * @methodOf site.signin
*/
site.signin.getPasswordHint = function(args) {
    if (!args.email) {
        return null;
    }
    var onSuccess = args.onSuccess || function() {};
    var onFailure = args.onFailure || function() {};


    generic.jsonrpc.fetch({
        method: 'user.passwdHint',
        params: [{
            "EMAIL_ADDRESS": args.email
        }],
        onSuccess : onSuccess,
        onFailure : onFailure
    });
};


/**
    * This method is used to reset a users password by submitting a hidden form.
    * @param {email} the user's email address **REQUIRED**
    * @param {actionURL} the page URL of the reset page **REQUIRED**
    * **NOTE**: The error check for if an account exists is handled by the password
    * hint function. The reset is hidden inside the password hint function
    * so no duplicate error checking is needed here.
*/
site.signin.initResetPassword = function(emailNode) {
    //have to initialise the link here because it isn't on the page until the pw hint method is invoked
    var email = site.signin.getEmailAddress(emailNode);
    var resetPassLink = $('#pwd-reset');
    if (resetPassLink) {
        resetPassLink.on('click', function(evt) {
            evt.preventDefault();
            site.signin.requestPassword(email);
        });
    }
};


/**
    * This method is used to direct the user to registration.tmpl or password_request.tmpl.
    * The passed values are injected into the genric form before it is submitted.
    * @param {email} the user email that will be passed. **REQUIRED**
    * @param {actionURL} action url used on user submit. **REQUIRED**
    * @param {returnURL} passed when an action is needed after the user
    * has gone to the next template page. **NOT REQUIRED**
    * Example case for returnURL is if the user goes through checkout and registers,
    * the returnURL is used to pass the viewbag action url to the registration page. Once
    * registration form is filled out, user will be brought to viewbag.
    * @methodOf site.signin
*/
site.signin.submitHiddenSigninForm = function(args) {
    if (!args.actionURL || !site.signin.hiddenForm) {
        return null;
    }
    site.signin.hiddenForm.attr('action', args.actionURL);
    //console.log('actionURL : ' + args.actionURL);
    //console.log('returnURL : ' + args.returnURL);
    var hiddenEmailNode = $('#sigin-form-hidden-email');
    hiddenEmailNode.val(args.email);
    if (args.returnURL) {
        var hiddenReturnNode = $('#sigin-form-hidden-return');
        hiddenReturnNode.val(args.returnURL);
    }
    site.signin.hiddenForm.trigger('submit');
};


/**
    * This method is used to call site.signin.submitHiddenSigninForm by
    * passing the user's email used in the reset form submit.
    * @param {String} the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
*/
site.signin.requestPassword = function(emailAddr) {
    site.signin.hiddenForm = $('#signin-hidden-form');
    if (site.signin.hiddenForm) {
        site.signin.submitHiddenSigninForm({
            email: emailAddr,
            actionURL: '/account/password_request.tmpl'
        });
    }
}

/**
    * This method is used to pull the user's email from either a form
    * input or container html tag wrapper (i.e. div, span, etc)
    * @param {String} emailNode the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
*/
site.signin.getEmailAddress = function(emailNode) {
    if(!emailNode ) return null;
    return emailNode.val();
}

/**
    * Handles password input switching for IE8/9 on Clinique CA Register pages 
    * IE8/9 do not allow input type switching - Also tested with Firefox and Safari
*/
// ARE WE HERE?
site.signin.togglePasswordField = function(e, passwordField) {

    var passStr    = '';
    var tInpStr    = '';
    var pInpStr    = '';
    var pRemStr    = '';
    var insertLoc  = '';
    var pSel       = '';
    var regType;
    var isPG;
    var isChecked;
    var $checkBox  = $(e.target);
  var togglePasswordLabel = generic.rb('language').get('password_criteria');

    // IE does not support e.target
    if( $checkBox.length == 0 ) {
        isPG = e.srcElement.className == 'signin_show_password' ? true : false;
        isChecked = e.srcElement.checked;
    } else {
        isPG      = $checkBox.hasClass('signin_show_password')
        isChecked = $checkBox.is(':checked');
    }

    if( !isPG ) {
        // Called from gnav_my_account_v1.js
        pSel       = 'q_password2';
        $passBox   = $( '#'+pSel );
        regType    = 0;
        tInpStr    = '<input id="q_password2" class="form-text" name="PASSWORD" type="text" maxlength="12" />';
        pInpStr    = '<input id="q_password2" class="form-text" name="PASSWORD" type="password" maxlength="12" />';
        pRemStr    = '[id='+pSel+']';
        insertLoc  = 'div[class=show]';
    } else {
        // Called from registration_short.tmpl or from checkout_signin_new_user.tmpl
        pSel = 'form--checkout_signin_new_user--field--PASSWORD';
        pSel = passwordField !== undefined ? passwordField : $( '#'+pSel ).length > 0 ? pSel : 'form--registration_short--field--PASSWORD';
        $passBox   = $( '#'+pSel );
        regType    = 1;
    tInpStr = '<input id="' + pSel + '" class="field error" type="text" placeholder="' + togglePasswordLabel + '" name="PASSWORD" value="" maxlength="12">';
    pInpStr = '<input id="' + pSel + '" class="field error" type="password" placeholder="' + togglePasswordLabel + '" name="PASSWORD" value="" maxlength="12">';
        pRemStr    = '[id='+pSel+']';
        insertLoc  = 'span[class=show]';
    }

    passStr = $passBox.val();

    if( isChecked ) { 
        $( pRemStr ).remove();
        $( tInpStr ).insertBefore( $( insertLoc ) ); 
    } else {
        $('input[type=text][name=PASSWORD]').remove();
        $( pInpStr ).insertBefore( $( insertLoc ) ); 
    }   

    $passBox = $( '#'+pSel );
    if( passStr !== "" ) { 
        $passBox.val( passStr );
    }   
    site.account.initPasswordValidationTooltip();
}

/**
 *
 * @methodOf site.signin
 */
site.signin.initGuestCheckoutShowPassword = function(passwordField) {
    //show password
    $('#show-passwd').on('click', function(e) {
        site.signin.togglePasswordField(e, passwordField);
    });
   //Benefits overlay for guest checkout
    var $benefitsOverlayContParent = $('.guest_checkout_wrapper');
    if (site.client.isMobile) {
        var $benefitsOverlayCont = $('.benefits-overlay-container');
        $benefitsOverlayCont.find('.benefits-close').on('click', function() {
            $benefitsOverlayCont.addClass('hidden');
            $benefitsOverlayContParent.removeclass('mobile_benefits_overlay');
        });
        $('.benefits-overlay-link').on('click', function(e) {
            e.preventDefault();
            $benefitsOverlayContParent.addClass('mobile_benefits_overlay');
            $benefitsOverlayCont.removeClass('hidden');
        });
    }
    else {
        var $benefitsOverlayCont = $('.benefits-overlay-container').clone();
        $benefitsOverlayContParent.append($benefitsOverlayCont).css('position', 'relative');

        $benefitsOverlayCont.find('.benefits-close').on('click', function() {
            $(this).parent('.benefits-overlay-container').addClass('hidden');
        });

        $benefitsOverlayContParent.on('click', '.benefits-overlay-link', function() {
            $(this).siblings('.benefits-overlay-container').removeClass('hidden');
        });
    }
}
